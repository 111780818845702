<template>
	<div>
		<ContentHeader3 title="Tindakan" url="/tindakan" title2="Data Tindakan" url2="/data-tindakan" subTitle="Isi Data Paket" />
		<div class="content-header">
			<div class="container-fluid">
				<div class="row justify-content-center">
		            <div class="col">
		            	<div class="card">
		            		<div class="card-header row">
		            			<h3 class="card-title">Isi Data Paket</h3>
		            		</div>
		            		<div class="card-body">
								<div class="form-group">
				                  	<p class="text-danger">{{ error }}</p>
				                  	<div class="row">
				                  		<div class="col-12 col-md-6">
				                  			<label for="tindakan">Tindakan</label>
						                    <select class="custom-select form-control" id="tindakan" name="tindakan" required="" v-model="tindakan_index">
	      										<option selected hidden="">Pilih Tindakan Yang Ingin Ditambahkan</option>
	      										<option v-for="(row, index) in tindakan" :key="row.id" v-bind:value="index">
	      											{{ row.kode_tindakan }} - {{ row.nama }}
	      										</option>
		    								</select>
				                  		</div>
				                  		<div class="col col-md-1">
				                  			<label for="jumlah">Jumlah</label>
				                  			<input class="form-control" type="number" min="1" v-model="jumlah" name="jumlah">
				                  		</div>
				                  		<div class="col-12 col-md-5 d-flex align-items-end">
				                  			<button class="btn btn-primary" @click="tambahkanTindakan()">Tambahkan</button>
				                  		</div>
				                  	</div>
				                  	<div class="row mt-4">
				                  		<div class="col">
				                  			<table class="table table-bordered table-hover">
											  <thead>
											    <tr>
											      <th scope="col" class="text-sm">NO</th>
											      <th scope="col" class="text-sm">KODE</th>
											      <th scope="col" class="text-sm">NAMA</th>
											      <th scope="col" class="text-sm">JUMLAH</th>
											      <th scope="col" class="text-sm">ACTION</th>
											    </tr>
											  </thead>
											  <tbody>
											  	<tr v-for="(row, index) in tindakan_yang_diambil" :key="row.tindakan_id">
											  		<td>{{ index + 1 }}</td>
											  		<td>{{ row.kode }}</td>
											  		<td>{{ row.nama }}</td>
											  		<td>{{ row.jumlah }}</td>
											  		<td><a class="btn btn-block btn-outline-danger btn-xs" @click="hapusDataTindakan(row.kode)">Hapus</a></td>
											  	</tr>
											  </tbody>
											</table>
											  <p class="text-center" v-if="memuat_data">Memuat Data</p>
											<button @click="prosesSubmit" class="btn btn-primary d-flex align-items-center">
											  <div>
											  	Submit
											  </div>	
											  <div>
							                    <svg v-if="loading" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style=" background: none; display: block; shape-rendering: auto;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
							                      <g>
							                        <path d="M50 15A35 35 0 1 0 74.74873734152916 25.251262658470843" fill="none" stroke="#ffffff" stroke-width="12"></path>
							                        <path d="M49 3L49 27L61 15L49 3" fill="#ffffff"></path>
							                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
							                      </g>

							                    </svg>
							                  </div>
											</button>
				                  		</div>
				                  	</div>
				                  </div>
		            		</div>
		            	</div>
		            </div>
		        </div>
			</div>
		</div>
	</div>
</template>

<script>
import ContentHeader3 from '@/components/ContentHeader3'

import { onMounted, ref, computed, reactive } from 'vue'
import axios from 'axios'
import store from '@/store'
import { useRoute } from 'vue-router'
import Swal from 'sweetalert2'
import router from '@/router'

export default  {
	components: {
		ContentHeader3: ContentHeader3
	},
	setup(){
		const route = useRoute()
		const paket_id = route.params.id
		const paket = ref([])
		const tindakan = ref([])
		const error = ref('')
		const loading = ref(false)
		const memuat_data = ref(true)
		
		// Variable untuk v-model
		const tindakan_index = ref('Pilih Tindakan Yang Ingin Ditambahkan')
		const jumlah = ref(1)
		// End Variable untuk v-model

		const tindakan_yang_diambil = ref([])


		const user = computed(() => store.getters['auth/user'])

		const getPaket = async () => {
			let { data } = await axios.get(`api/tindakan/getWhereId/${paket_id}`)
			paket.value = data
			console.log(paket.value)
		}

		const getTindakan = async () => {
			let { data } = await axios.get(`api/tindakan/getWhereTindakan/${user.value.cabang_id}`)
			
			if (data == 'kosong') {
				error.value = 'Tidak Ada Data Tindakan'
				tindakan.value = ''
			} else {
				tindakan.value = data
				console.log(tindakan.value)
			}
		} 

		const getTindakanYangPernahDiambil = async () => {
			let { data } = await axios.get(`api/tindakan/getPaketDetail/${paket_id}`)
			if (data != 'kosong') {
				tindakan_yang_diambil.value = data
				memuat_data.value = false
			} else {
				memuat_data.value = false
			}
		}

		const tambahkanTindakan = () => {
			if (tindakan_index.value == 'Pilih Tindakan Yang Ingin Ditambahkan') {
				error.value = 'Pilih Tindakan Terlebih Dahulu'
			} else {

				let id = parseInt(tindakan_index.value)
				
				tindakan_yang_diambil.value.push({
					'tindakan_id' : tindakan.value[id].id,
					'kode' : tindakan.value[id].kode_tindakan,
					'nama' : tindakan.value[id].nama,
					'jumlah' : jumlah.value
				})

				tindakan_index.value = 'Pilih Tindakan Yang Ingin Ditambahkan'
				jumlah.value = 1
				error.value = ''
			}
		}

		const hapusDataTindakan = (kode) => {
			for(let j = 0; j < tindakan_yang_diambil.value.length; j++) {
				if (tindakan_yang_diambil.value[j].kode == kode) {
					tindakan_yang_diambil.value.splice(j, 1)
				}
			}
		}

		const prosesSubmit = () => {

			if (tindakan_yang_diambil.value.length == 0) {
				error.value = 'Pilih Tindakan Terlebih Dahulu'
			} else {
				loading.value = true
				
				let data = reactive({
					'paket_id' : paket_id,
					'tindakan' : tindakan_yang_diambil.value
				})

				axios.post(`api/tindakan/tambahTindakanKePaket`, data)
				.then((response) => {
					Swal.fire({
					  title: 'Berhasil!',
					  text: 'Berhasil Menambahkan Data',
					  icon: 'success',
					  showConfirmButton: false,
					  timer: 1500
					})	
					console.log(response)
					loading.value = false
					router.replace('/data-tindakan')
				})
				.catch((error) => {
					Swal.fire({
					  title: 'Gagal!',
					  text: 'Gagal menambahkan data',
					  icon: 'error',
					  confirmButtonText: 'Ok'
					})
					loading.value = false
					console.log(error)
				})

			}
		}

		onMounted(() => {
			getPaket()
			getTindakan()
			getTindakanYangPernahDiambil()
		})

		return {
			tindakan, tindakan_index, tambahkanTindakan, jumlah, tindakan_yang_diambil, error, loading, prosesSubmit, memuat_data, hapusDataTindakan
		}
	}
}
</script>